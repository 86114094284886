import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import './MainPage.css';
import Page1 from './Page1/Page1';
import Page2 from './Page2/Page2';
import Page3 from './Page3/Page3';
import Page4 from './Page4/Page4';

import cloudbase from '@cloudbase/js-sdk';
// import wx from 'weixin-js-sdk'


// 初始化 SDK
const app = cloudbase.init({
  env: 'ai-swapface-4gytlq7j6fff1db1' // 填入您的环境 ID
});

const characters = [
  'baishe',
  'chenxuee',
  'duliniang',
  'hongxiannv',
  'lifengjie',
  'muguiying',
  'sekong',
  'shangyuanfuren',
  'tiannv',
  'xishi',
  'yangguifei'
]

const bgImages = {
  baishe: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/baishe/baishe_bg2.jpg?sign=a1e4b4e68319b65447431ed8c5fdb0e5&t=1736006647',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/baishe/baishe_bg3.jpg?sign=17b48d6dea2059f2fe80b24fd337881b&t=1736006679'
  },
  chenxuee: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/chenxuee/chenxuee_bg2.jpg?sign=30ba40aadf0305bdd8a6d29db762ce07&t=1736007046',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/chenxuee/chenxuee_bg3.jpg?sign=fb6627641f70e485ee06e0212abc84f3&t=1736007831'
  },
  duliniang: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/duliniang/duliniang_bg2.jpg?sign=3c2d717f73f41243c58da22e285b5aea&t=1736007855',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/duliniang/duliniang_bg3.jpg?sign=de31267ace6d39916360833f0755142a&t=1736007862'
  },
  hingxiannv: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/hongxiannv/hongxiannv_bg2.jpg?sign=edc40182c0c408e457e760e6779b87c7&t=1736007877',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/hongxiannv/hongxiannv_bg3.jpg?sign=c1df5a7358d03ca81f2efae7f8e0d93c&t=1736007885'
  },
  lifengjie: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/lifengjie/lifengjie_bg2.jpg?sign=0989d1765795013a51c671626aaa65fd&t=1736007899',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/lifengjie/lifengjie_bg3.jpg?sign=b58e44b3b585435ca6fc8343d560432b&t=1736007907'
  },
  muguiying: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/muguiying/muguiying_bg2.jpg?sign=2a8a0c1b75715cc65b171ee61559e379&t=1736010135',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/muguiying/muguiying_bg3.jpg?sign=d37fd39250204234a876272155271304&t=1736010143'
  },
  sekong: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/sekong/sekong_bg2.jpg?sign=e0fae60d2fe46835d98baed9a9314423&t=1736010167',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/sekong/sekong_bg3.jpg?sign=43018e08bee9f458cf57c442e9cbfc2a&t=1736010173'
  },
  shangyuanfuren: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/shangyuanfuren/shangyuanfuren_bg2%20(1).jpg?sign=27eb5918fea08c95e03c52e409a7c887&t=1736010190',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/shangyuanfuren/shangyuanfuren_bg3%20(1).jpg?sign=7ea9149dfefe8988a32c045c44172f64&t=1736010205'
  },
  tiannv: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/tiannv/tiannv_bg2.jpg?sign=f2c613f2e6194a921e966649ed895ac6&t=1736010233',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/tiannv/tiannv_bg3.jpg?sign=756358bbe9e8d3a5409f4df5a23fdb10&t=1736010237'
  },
  xishi: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/xishi/xishi_bg2.jpg?sign=935ddce8001fd537a180b21085990d4c&t=1736010248',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/xishi/xishi_bg3.jpg?sign=e21db52b6966781134c18a01c0a650ad&t=1736010253'
  },
  yangguifei: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/yangguifei/yangguifei_bg2.jpg?sign=7a2932e66248b5be2af38187895bf6c9&t=1736010263',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/yangguifei/yangguifei_bg3.jpg?sign=37b05a0d76a950c62ea5484d6651aff8&t=1736010269'
  },
  yuji: {
    page2: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/yuji/yuji_bg2.jpg?sign=998def1218ede148ac6e1c2c7fa860d8&t=1736010278',
    page3: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/yuji/yuji_bg3.jpg?sign=31d1971c9df9ed1e57828ee356b250b8&t=1736010283'
  }
};

var auth = app.auth();

async function login(){
  await auth.signInAnonymously();
  // 匿名登录成功检测登录状态isAnonymous字段为true
  const loginState = await auth.getLoginState();
  console.log(loginState.isAnonymous); // true
}

login();

const MainPage = () => {
  const [character, setCharacter] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [videoBg, setVideoBg] = useState(null);
  const [bgImage2, setBgImage2] = useState(null);
  const [bgImage3, setBgImage3] = useState(null);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    // Clear cache on component mount
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);

  useEffect(() => {
    // Assume characters is defined in this file
    if (characters.length > 0) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      const characterParam = characters[randomIndex];
      console.log('Random character', characterParam);

      setCharacter(characterParam);
      setBgImage2(bgImages[characterParam].page2);
      setBgImage3(bgImages[characterParam].page3);
    } else {
      alert('缺少关键参数，请扫码重试！');
    }
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.onbeforeunload = null;
    }
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  useEffect(() => {
    const checkOrientation = () => {
      if (window.innerHeight < window.innerWidth) {
        document.getElementById('rotateDevice').style.display = 'block';
      } else {
        document.getElementById('rotateDevice').style.display = 'none';
      }
    };
    checkOrientation();
    window.addEventListener("resize", checkOrientation);
    return () => {
      window.removeEventListener("resize", checkOrientation);
    }
  }, []);

  console.log(character);

  return (
    <div>
      <div id="rotateDevice">请旋转手机以获得更好的体验</div>
      <Element name="page1" >
        <Page1 setOrderId={setOrderId}/>
      </Element>

      <Element name="page2">
        <Page2 character={character} bgImage={bgImage2} setUserImage={setUserImage} />
      </Element>

      <Element name="page3">
        <Page3 bgImage={bgImage3} setVideoBg={setVideoBg} />
      </Element>

      <Element name="page4">
        <Page4 character={character} bgImage={bgImage3} userImage={userImage} videoBg={videoBg} orderId={orderId}/>
      </Element>
    </div>
  );
};

export default MainPage;