// VideoShowPage.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './VideoShowPage.css';
import Hint from './Hint';
// import wx from 'weixin-js-sdk'

const bgImages = {
  baishe: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/baishe/baishe_result_bg.png?sign=77589b7bdf404af5e2421ec4c06b74ae&t=1736010574'
  },
  chenxuee: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/chenxuee/chenxuee_result_bg.png?sign=3b7585f147e03ffe12e153ff3ff92b75&t=1736010559'
  },
  duliniang: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/duliniang/duliniang_result_bg.png?sign=5f995cd527cd202245d022864f8aa73c&t=1736010549'
  },
  hingxiannv: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/hongxiannv/hongxiannv_result_bg.png?sign=c27661221c6e8b04d56e6fbac137a66f&t=1736010535'
  },
  lifengjie: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/lifengjie/lifengjie_result_bg.png?sign=bcce2132110af43e2872a8716a45cd81&t=1736010473'
  },
  muguiying: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/muguiying/muguiying_result_bg.png?sign=f87f01491ce1d6d59a6f53daf5750199&t=1736010457'
  },
  sekong: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/sekong/sekong_result_bg.png?sign=ac61015f88be3113f2e3b4affd2b964e&t=1736010441'
  },
  shangyuanfuren: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/shangyuanfuren/shangyuanfuren_result_bg%20(1).png?sign=2552fd8611208d3b19f516e0fee208b8&t=1736010426'
  },
  tiannv: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/tiannv/tiannv_result_bg.png?sign=31d54f59aa3f6cfd111bc17d8937bf05&t=1736010399'
  },
  xishi: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/xishi/xishi_result_bg.png?sign=bd968a1682828bc60004747b6c2df1d5&t=1736010388'
  },
  yangguifei: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/yangguifei/yangguifei_result_bg.png?sign=dd7d8347ded2b6d14407441661f6988d&t=1736010378'
  },
  yuji: {
    show: 'https://6169-ai-swapface-4gytlq7j6fff1db1-1314635850.tcb.qcloud.la/websiteUi/yuji/yuji_result_bg.png?sign=3c43c5d2b06e2d2f0f40ad2b9aec3cf7&t=1736010368'
  }
};

const VideoShowPage = () => {
  // const preventDefault = e => e.preventDefault();
  // window.addEventListener('touchmove', preventDefault, { passive: false });

  const [videoLink, setVideoLink] = useState(null);
  const [character, setCharacter] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [secondModalIsOpen, setSecondModalIsOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const linkParam = params.get('link');
    const characterParam = params.get('character');
    if (linkParam && characterParam) {
      console.log('linkParam', linkParam);
      setVideoLink(linkParam);
      console.log('characterParam', characterParam);
      setCharacter(characterParam);
    } else {
      alert('未找到视频源，请扫码重试！');
    }
  }, []);

  // useEffect(() => {
  //   wx.showMenuItems({
  //     menuList: ['menuItem:openWithSafari', 'menuItem:originPage', 'menuItem:openWithQQBrowser', 'menuItem:share:appMessage',]
  //   });
  // }, []);

  const downloadVideo = async (videoLink) => {
    const videoUrl = videoLink;
    try {
      const response = await fetch(videoUrl);
      if (response.status !== 200) {
        throw new Error('Error:', response.statusText);
      } else {
        setSecondModalIsOpen(true);
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = 'video.mp4';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    } catch (e) {
      console.error('Error:', e);
      alert('Error:' + e);
    }
  }

  function closeModal() {
    setSecondModalIsOpen(false);
  }

  const isWeChat = /micromessenger/i.test(navigator.userAgent);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const videoRef = React.useRef(null);
  const isAndroid = /Android/.test(navigator.userAgent);

  // const test = () => {
  //   console.log('test');
  // }
  const test = () => {
    console.log('test');
    if (isAndroid) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) { /* Firefox */
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) { /* IE/Edge */
        videoRef.current.msRequestFullscreen();
      }
      videoRef.current.play();
    }
  };

  if (!videoLink) {
    return null;
  }

  const handleModalTest = () => {
    setModalIsOpen(true);
    setTimeout(() => {
      setModalIsOpen(false);
    }, 10000);
  };

  return (
    <div className="page video-show-page">
      <div className='bg-wrapper'>
        <img src={bgImages[character].show} alt="background" className="bg" />
      </div>
      {/* <video className='video' autoPlay loop playsInline> */}
      {/* <div className='video-container' onClick={test}>
        {isWeChat || isIOS ? (
          <video className='video' controls loop playsInline>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <video className='video' controls loop>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div> */}
      <div className='video-container' onClick={test}>
        {isWeChat || isIOS ? (
          <video ref={videoRef} className='video' controls loop playsInline>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <video ref={videoRef} className='video' controls loop>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {isWeChat ? (
        <div className='download-container'>
          <img src="/assets/pageShow/pageShow_btn.png" alt="download" className="download-btn" onClick={handleModalTest} />
        </div>
      ) : (
        <div className='download-container'>
          <img src="/assets/pageShow/pageShow_btn.png" alt="download" className="download-btn-out" onClick={() => downloadVideo(videoLink)} />
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName="PS_ReactModal__Overlay"
        className="PS_ReactModal__Content"
      >
        <Hint />
      </Modal>

      <Modal
        isOpen={secondModalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="PS_ReactModal__Overlay2"
        className="PS_ReactModal__Content2"
      >
        <h2>下载即将开始...</h2>
        <p>视频文件将下载至"下载"文件夹</p>
        <button onClick={closeModal}>我已知晓</button>
      </Modal>
    </div>
  );
};

export default VideoShowPage;